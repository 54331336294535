import React, { useContext, useEffect } from 'react';
// REDUX IMPORTS
import { useSelector } from 'react-redux';
// REACT ROUTER IMPORTS
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
// MATERIAL IMPORTS
import {
    Box,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    tableCellClasses,
    TablePagination,
    Stack, Chip, CircularProgress, Button

} from '@material-ui/core';
import {
    DynamicFeedOutlined,
    Announcement, ArrowDownward, ArrowUpward, Assignment,
    Check, Comment,
    ThumbUp,
} from '@material-ui/icons';
// NPM IMPORTS
import moment from "moment";
import PropTypes from 'prop-types';
// CONTEXT IMPORTS
import { AIContext } from '../../../contexts/AIContext';
import { EmpContext } from '../../../contexts/employeecontext';
import { PATH_EMP } from '../../../routes/paths';

// ----------------------------------------------------------------------------------------------------------

CoachingSummary.propTypes = {
    theme: PropTypes.string,
    themeColor: PropTypes.object
}

const TableHeaders = [
    { name: 'No', id: 'no' },
    { name: 'Coaching Date', id: 'cd' },
    { name: 'Follow-up Date', id: 'fd' },
    { name: 'Acknowledgement Status', id: 'As' },
    // { name: 'Acknowledgement Date', id: 'ad' },
    // { name: 'Evaluation Status', id: 'es' },
    { name: 'Action', id: 'act' }
]

export default function CoachingSummary({ theme, themeColor }) {
    // LOCAL STORAGE
    // const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
    const { fetchViewCoachingSessionIntialData,
        viewCoachingTableData, setViewCoachingTableData,
        viewCoachingTableCount, setViewCoachingTableCount,
        viewCoachingTableLoader, setViewCoachingTableLoader,
        viewCoachingTablePage, setViewCoachingTablePage,
        selectedFromDate, selectedToDate,
        selectedGroup, selectedTeam,
        agentReportAgent,
        coachFilter, coachingAreaFilter,

    } = useContext(AIContext)
    const { datesData } = React.useContext(EmpContext);

    const navigate = useNavigate();
    // REACT ROUTER STATES
    const [searchParams, setSearchParams] = useSearchParams();

    const getCoachingAreasName = (arr) => {
        const tempArr = arr?.map(obj => obj?.name)?.join(',')
        console.log(arr, tempArr)
        return tempArr
    }

    const handlePagination = (newPage) => {
        setViewCoachingTablePage(newPage)
        fetchViewCoachingSessionIntialData(selectedFromDate, selectedToDate, selectedGroup, selectedTeam, agentReportAgent, coachFilter, coachingAreaFilter, newPage + 1)
    }

    useEffect(() => {
        if (datesData?.date1) {
            fetchViewCoachingSessionIntialData(datesData?.date1, datesData?.date2, selectedGroup, selectedTeam, agentReportAgent, coachFilter, coachingAreaFilter, 1);
        }
    }, [datesData])



    return (
        <Box sx={{ backgroundColor: 'white' }} >
            {!viewCoachingTableLoader && <>
                <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ p: 2, borderBottom: theme => `2px solid ${theme.palette.text.primaryBolderBorder}` }} >
                    <Typography sx={{ color: theme => theme.palette.text.primary, fontSize: '16px', fontWeight: 'bold' }}>Coaching Session</Typography>
                    <Button onClick={() => navigate(PATH_EMP.coaching.viewCoaching)} size='small' >View All Coaching Session</Button>
                    {/* <SearchStyle placeholder='Search...'
                        value={filterName}
                        endAdornment={
                            <>
                                <InputAdornment position='start'>
                                    <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                                </InputAdornment>
                            </>
                        }
                        onChange={handleFilterName}
                    /> */}
                    {/* <TablePagination
                        page={viewCoachingTablePage}
                        count={viewCoachingTableCount}
                        rowsPerPage={100}
                        rowsPerPageOptions={[100]}
                        onPageChange={handlePagination}
                        sx={{ border: '1px solid transparent' }}
                    /> */}
                </Stack>
                <TableContainer sx={{ px: 1, py: 1, mb: 1 }}>
                    <Table size='small'
                        sx={{
                            [`&.${tableCellClasses?.root}`]: {
                                border: '1px solid #ddd2e6'
                            },
                            "&.MuiTableCell-root:first-of-type": {
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                                boxShadow: 'none'
                            },
                            "&.MuiTableCell-root:last-of-type": {
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                                boxShadow: 'none'
                            }
                        }}
                    >
                        <TableHead>
                            <TableRow>
                                {TableHeaders?.map(obj => <TableCell align='center'>{obj?.name}</TableCell>)}
                            </TableRow>

                        </TableHead>
                        {viewCoachingTableData?.length > 0 && <TableBody>
                            {viewCoachingTableData?.slice(0, 5)?.map(obj => <TableRow sx={{ [`&.${tableCellClasses?.root}`]: { py: 2 } }}>
                                <TableCell align='center'>{!obj?.is_draft ? obj?.id : 'In-Draft'}</TableCell>
                                <TableCell align='center'>{obj?.coaching_date ? moment(obj?.coaching_date).format('ll') : '-'}</TableCell>

                                <TableCell align='center'>

                                    <>
                                        {obj?.follow_up_date ? moment(obj?.follow_up_date).format('ll') : '-'}
                                    </>
                                </TableCell>
                                <TableCell align='center'>

                                    {obj?.is_draft && <>
                                        -
                                    </>}
                                    {
                                        !obj?.is_draft && <Chip size='small' label={obj?.is_coaching_accepted ? 'Accepted' : 'Pending'} sx={{ borderRadius: '5px', backgroundColor: obj?.is_coaching_accepted ? '#55efc4' : '#fdcb6e', "& .MuiChip-label": { color: "white" } }} />
                                    }
                                </TableCell>

                                {/* <TableCell align='center'>test</TableCell> */}
                                <TableCell align='center'>
                                    <Stack direction='row' alignItems='center' justifyContent='center'>
                                        <Button onClick={() => navigate(`${PATH_EMP?.coaching?.createCoaching}?agentId=${obj?.agent?.id}&csId=AR&evaluator=${obj?.coach?.name}&agent=${obj?.agent?.name}&id=${obj?.id}&is_draft=${obj?.is_draft ? 'True' : 'False'}&isEdit=${obj?.is_draft ? 'False' : 'True'}&isAccepted=${Boolean(obj?.coaching_accepted_on)}&acceptedOnDate=${obj?.coaching_accepted_on}`)} size='small' >View</Button>
                                    </Stack>
                                </TableCell>
                            </TableRow>)}
                        </TableBody>}
                    </Table>
                    {viewCoachingTableData?.length === 0 && <Stack sx={{ my: 1, py: 5, width: '100%' }} direction='row' alignItems='center' justifyContent='center' justifyItems='center'>
                        <Typography sx={{ fontSize: '13px', fontWeight: 'bold', mr: 1 }}>
                            No Data Found
                        </Typography>
                        <DynamicFeedOutlined size='small' />
                    </Stack>}
                </TableContainer>
            </>}
            {
                viewCoachingTableLoader && <Stack sx={{ py: 3 }} direction='row' alignItems='center' justifyContent='center'>
                    <Typography sx={{ fontSize: '13px', fontWeight: 'bold' }}>Loading...</Typography>&nbsp;<CircularProgress />
                </Stack>
            }
        </Box>
    )
}
